import React from "react";
import Navigation from "../components/Navigation";
import Mouse from "../components/Mouse";

const Project3 = () => {
  return (
    <div>
      <Mouse />
      <Navigation />
      <h1>Projet 3</h1>
    </div>
  );
};

export default Project3;
