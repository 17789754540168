import React from "react";
import Navigation from "../components/Navigation";
import Mouse from "../components/Mouse";

const Project1 = () => {
  return (
    <div>
      <Mouse />
      <Navigation />
      <div className="container-projet">
        <h1>Netflix</h1>
        <p>
          Attention , ceci est une copie visuel du site netflix sans aucun
          fonctiionnement, le but etait d'entrainer mon oeil et mes compétence
          de developpement front-end
        </p>
        <div className="img">
          <img src="./img/Capture d’écran 2023-10-24 à 14.06.05.png" alt="" />
          <button>
            <a href="https://netflix-clone-project-hadrien.netlify.app/">
              {" "}
              Découvir le site web
            </a>
          </button>
          <div className="hover"></div>
        </div>
      </div>
    </div>
  );
};

export default Project1;
