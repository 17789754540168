import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Project3 from "./pages/Project3";
import Project1 from "./pages/Project1";
import Project2 from "./pages/Project2";
import Notfound from "./pages/Notfound";
import { AnimatePresence } from "framer-motion";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contact" element={<Contact />}>
              contact
            </Route>
            <Route path="/projet-1" element={<Project1 />}></Route>
            <Route path="/projet-2" element={<Project2 />}></Route>
            <Route path="/projet-3" element={<Project3 />}></Route>
            <Route path="/notfound" element={<Notfound />}></Route>
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </div>
  );
};

export default App;
