import React from "react";
import Navigation from "../components/Navigation";
import Mouse from "../components/Mouse";
import { motion } from "framer-motion";
import Contact from "./Contact";
import Formulaire from "../components/Formulaire";

const Home = () => {
  const page = {
    initial: {
      opacity: 0,
      transition: { duration: 0 },
      x: 100,
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0 },
      x: -100,
    },
  };
  const titre = {
    initial: {
      opacity: 0,
      transition: { duration: 0, delay: 0.5 },
      x: 100,
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.8, delay: 0.5 },
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0, delay: 0.5 },
      x: -100,
    },
  };
  const secondtitre = {
    initial: {
      opacity: 0,
      transition: { duration: 0, delay: 1 },
      x: 100,
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.8, delay: 1 },
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0, delay: 1 },
      x: -100,
    },
  };
  const fond = {
    initial: {
      width: 0,
      transition: { duration: 0 },
    },
    visible: {
      width: 100 + "%",
      transition: { duration: 0.5 },
    },
    exit: {
      width: 0,
      transition: { duration: 0 },
    },
  };
  return (
    <div>
      <Mouse />
      <Navigation />
      <motion.div
        className="home"
        initial="initial"
        animate="visible"
        exit="exit"
        variants={fond}
      >
        <div className="home-container">
          <div className="left-container">
            <motion.h1
              initial="initial"
              animate="visible"
              exit="exit"
              variants={titre}
            >
              Janssens <br />
              Hadrien ,
            </motion.h1>
            <motion.h2
              initial="initial"
              animate="visible"
              exit="exit"
              variants={secondtitre}
            >
              I'm Developper web
            </motion.h2>
          </div>
          <div className="right-container">
            <motion.img
              initial="initial"
              animate="visible"
              exit="exit"
              variants={page}
              src="./img/IMG_4809.PNG "
              alt=""
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
