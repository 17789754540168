import React from "react";
import Navigation from "../components/Navigation";
import Mouse from "../components/Mouse";

const Project2 = () => {
  return (
    <div>
      <Mouse />
      <Navigation />
      <div className="container-projet">
        <h1>Projet 2</h1>
        <a href="http://hadrien-janssens-dev.netlify.app">
          {" "}
          Découvrir le projet
        </a>
        <p>
          Attention ceci est une copie du site de{" "}
          <a href="https://bepatrickdavid.com/"> patrick david </a> le but était
          uniquement de progresser en html css et javascript à l'aide de React
          et Sass{" "}
        </p>
      </div>
    </div>
  );
};

export default Project2;
