import Navigation from "../components/Navigation";
import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";

const Contact = () => {
  const page = {
    initial: {
      opacity: 0,
      transition: { duration: 0 },
      x: 100,
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0 },
      x: -100,
    },
  };
  const formulaireanimation = {
    initial: {
      opacity: 0,
      transition: { duration: 0, delay: 0.5 },
      y: 100,
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.8, delay: 0.5 },
      y: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0, delay: 0.5 },
      y: -100,
    },
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_flhge9m",
        "template_d8ibw66",
        form.current,
        "iRwlAw5DQ5lTQrYfj"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.href = "http://localhost:3000/";
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <Navigation />
      <motion.div
        // initial="initial"
        // animate="visible"
        // exit="exit"
        // variants={page}
        className="formulaire-container"
      >
        <motion.h1
          initial="initial"
          animate="visible"
          exit="exit"
          variants={page}
        >
          Contactez-moi
        </motion.h1>
        <motion.form
          ref={form}
          onSubmit={sendEmail}
          className="formulaire  box"
          initial="initial"
          animate="visible"
          exit="exit"
          variants={formulaireanimation}
        >
          <label>Name</label>
          <div className="input-div">
            <input
              type="text"
              name="name"
              placeholder="Qui es-tu ?"
              autoComplete="off"
              required
            />
          </div>
          <label>Email</label>
          <div className="input-div">
            <input
              autoComplete="off"
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
            />
          </div>
          <label>Message</label>
          <div className="input-div">
            <textarea
              name="message"
              placeholder=" Salut ! 👋 
      Dis-moi tout ! 🧐 "
              required
            />
          </div>
          <input id="btn-form" type="submit" value="Envoyer" />
        </motion.form>
      </motion.div>
    </div>
  );
};

export default Contact;
