import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <div className="navigation-container">
      <ul className="navigation">
        <NavLink to="/">
          {" "}
          <li>Home</li>
        </NavLink>
        <li className="portfolio ">
          {" "}
          Portfolio
          <ul className="projects">
            <NavLink to="/projet-1">
              {" "}
              <li>Projet Netflix</li>
            </NavLink>
            <NavLink to="/projet-2">
              {" "}
              <li>Projet Patrick David</li>
            </NavLink>
            <NavLink to="/projet-3">
              {" "}
              <li>Projet 3</li>
            </NavLink>
          </ul>
        </li>
        <NavLink to="/contact">
          {" "}
          <li>Contact</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;
