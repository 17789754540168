import React, { useEffect } from "react";

const Mouse = () => {
  useEffect(() => {
    const bubble = document.querySelector(".bubble");
    window.addEventListener("mousemove", (e) => {
      bubble.style.left = e.x + "px";
      bubble.style.top = e.y + "px";
    });
  }, []);

  return <div className="bubble"></div>;
};

export default Mouse;
