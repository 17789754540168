import React from "react";

const Notfound = () => {
  return (
    <div>
      <h1>Erreur 404</h1>
    </div>
  );
};

export default Notfound;
